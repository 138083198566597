body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: 'header';
  font-style: normal;
  font-weight: normal;
  src: local('futura-condensed'), url('./fonts/futura-condensed.woff') format('woff');
} */

@font-face {
  font-family: 'futuracon-exbol';
  font-style: normal;
  font-weight: normal;
  src: local('futuracon-exbol'), url('./fonts/FuturaCon-Exbol.otf') format('opentype');
}

@font-face {
  font-family: 'futura-bol';
  font-style: normal;
  font-weight: normal;
  src: local('futura-bol'), url('./fonts/Futura-Bol.otf') format('opentype');
}

@font-face {
  font-family: 'futura-med';
  font-style: normal;
  font-weight: normal;
  src: local('futura-med'), url('./fonts/Futura-Med.otf') format('opentype');
}

@font-face {
  font-family: 'muli-reg';
  font-style: normal;
  font-weight: normal;
  src: local('muli-reg'), url('./fonts/Muli-Reg.otf') format('opentype');
}
