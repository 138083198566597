
.hero-converter-block__converter-box {
    width: 100%;
    display: none;
}

.hero-converter-summary{
    display: flex; 
    flex-direction: column;
    height: auto; 
    margin: 0 auto; 
    background-color: #f5f5f5; 
    padding: 20px; 
    box-shadow: 10px; 
    margin-bottom: 20px;
    box-sizing: border-box;
    max-width: 400px;
}

.align-items-center{
    display: flex;
    align-items: center;
}

.flex-direction-column{
    display: flex;
    flex-direction: column;
}

.hero-converter-summary-section{
    width: 100%;
}

.hero-converter-summary-order{
    margin: 20px auto 0 auto;
}

 /* style={{width: '50%', display: 'flex', flexDirection: 'column'}} */

@media only screen and (max-width: 380px) {

    .forex-logo {
        margin: 0 auto;
        width: 200px;
        padding-top: 20px;
    }

    .currency-title {
        font-size: 22px;
    }

    .currency-preamble {
        max-width: 90%
    }

    .hero-converter-block__converter-box {
        margin: 0px;
    }

    .vert-currencies {
        display: block;
    }

    .converter-form {
        flex-direction: column;
        align-items: center;
        z-index: 100;
        position: relative;
    }

    .converter-box-title {
        display: none;
    }

    .summary-wrapper {
        flex-direction: column;
        width: 100%;
    }

    .half-width {
        width: 90%;
        position: relative;
    }

    .info-box {
        left: -150px;
    }

    .button-container {
        margin: 40px auto 20px auto;
    }

    .small-font {
        font-size: 14px;
    }

    .from-converter-box {
        margin-right: 0px;
    }

    .to-converter-box {
        margin-left: 0px;
    }

    .forex-logo-vert {
        margin: 0 auto;
        width: 200px;
        padding-top: 20px;
    }

    .currency-title-vert {
        font-size: 22px;
    }

    .currency-preamble-vert {
        width: 90%
    }

    .converter-form-vert {
        flex-direction: column;
        align-items: center;
        z-index: 100;
        position: relative;
    }

    .converter-box-title-vert {
        display: none;
    }

    .summary-wrapper-vert {
        flex-direction: column;
        width: 100%;
    }

    .half-width-vert {
        width: 90%;
        position: relative;
    }

    .info-box-vert {
        left: -150px;
    }

    .button-container-vert {
        margin: 40px auto 20px auto;
    }

    .small-font-vert {
        font-size: 14px;
    }
}

@media only screen and (min-width: 380px) {
    .wrapper {
        width: 100%;
    }

    .forex-logo {
        margin: 0 auto;
        width: 200px;
        padding-top: 20px;
    }

    .currency-title {
        font-size: 22px;
    }

    .currency-preamble {
        width: 90%
    }

    .vert-currencies {
        display: block;
    }

    .converter-form {
        flex-direction: column;
        align-items: center;
        z-index: 100;
        position: relative;
    }

    .converter-box-title {
        display: none;
    }

    .from-converter-box {
        margin-right: 0px;
    }

    .to-converter-box {
        margin-left: 0px;
    }

    .summary-wrapper {
        flex-direction: column;
        width: 100%;
    }

    .info-box {
        left: -150px;
    }

    .button-container {
        margin: 40px auto 20px auto;
    }

    .half-width {
        width: 90%;
        position: relative;
    }

    .small-font {
        font-size: 14px;
    }

    .wrapper-vert {
        width: 100%;
    }

    .forex-logo-vert {
        margin: 0 auto;
        width: 200px;
        padding-top: 20px;
    }

    .currency-title-vert {
        font-size: 22px;
    }

    .currency-preamble-vert {
        width: 90%
    }

    .converter-form-vert {
        flex-direction: column;
        align-items: center;
        z-index: 100;
        position: relative;
    }

    .converter-box-title-vert {
        display: none;
    }

    .summary-wrapper-vert {
        flex-direction: column;
        width: 100%;
    }

    .info-box-vert {
        left: -150px;
    }

    .button-container-vert {
        margin: 40px auto 20px auto;
    }

    .half-width-vert {
        width: 90%;
        position: relative;
    }

    .small-font-vert {
        font-size: 14px;
    }
}

@media only screen and (min-width: 400px) {
    .wrapper {
      width: 100%;
    }

    .summary-wrapper {
        flex-direction: column;
        width: 80%;
    }

    .half-width {
        width: 100%;
        position: relative;
    }

    .wrapper-vert {
        width: 100%;
      }
  
      .summary-wrapper-vert {
          flex-direction: column;
          width: 80%;
      }
  
      .half-width-vert {
          width: 100%;
          position: relative;
      }
  }

@media only screen and (min-width: 450px) {
    .wrapper {

    }

    .forex-logo {
        margin: 0 auto;
        width: 200px;
        padding-top: 20px;
    }

    .currency-title {
        font-size: 22px;
    }

    .currency-preamble {
        max-width: 806px;
    }

    .converter-box-title {
        display: none;
    }

    .converter-form {
        flex-direction: column;
    }

    .from-converter-box {
        margin: 0 auto;
        max-width: 400px;
        transform: scale(1);
    }

    .to-converter-box {
        margin: 0 auto;
        max-width: 400px;
        transform: scale(1);
    }

    .summary-wrapper {
        flex-direction: column;
        width: 360px;
    }

    .info-box {
        left: -100px;
    }

    .button-container {
        margin: 40px auto 20px auto;
    }

    .small-font {
        font-size: 16px;
    }

    .wrapper-vert {
        width: 450px;
    }

    .forex-logo-vert {
        margin: 0 auto;
        width: 200px;
        padding-top: 20px;
    }

    .currency-title-vert {
        font-size: 22px;
    }

    .currency-preamble-vert {
        width: 90%
    }

    .converter-box-title-vert {
        display: none;
    }

    .converter-form-vert {
        flex-direction: column;
    }

    .from-converter-box-vert {
        margin: 0 auto;
        max-width: 400px;
        transform: scale(1);
    }

    .to-converter-box-vert {
        margin: 0 auto;
        max-width: 400px;
        transform: scale(1);
    }

    .summary-wrapper-vert {
        flex-direction: column;
        width: 360px;
    }

    .info-box-vert {
        left: -100px;
    }

    .button-container-vert {
        margin: 40px auto 20px auto;
    }

    .small-font-vert {
        font-size: 16px;
    }
  }

  @media only screen and (min-width: 840px) {
    .forex-logo {
        margin: 0;
        width: 200px;
        padding-top: 0px;
    }
    
    .converter-form {
        flex-direction: row;
        width: 806px; 
    }

    .converter-box-title {
        display: block;
    }

    .from-converter-box {
        margin-right: 3px;
    }

    .to-converter-box {
        margin-left: 3px;
    }

    .vert-currencies {
        display: none;
    }
    .hero-converter-summary{
        flex-direction: row;
        width: 100%;
        max-width: 806px;
    }    
    .hero-converter-summary-section{
        width: 50%;
    }    
    .hero-converter-summary-order {
        margin: auto;
        padding-left: 30px;
    }    
  }

  @media only screen and (min-width: 992px) {
    .wrapper-vert {
        width: 450px;
    }

    .currency-title {
        font-size: 26px;
    }

    .currency-preamble {
        max-width: 806px;
    }

    .converter-box-title {
        display: block;
    }

    .mobile-box-title {
        display: none;
    }

    .converter-form {
        flex-direction: row;
        width: 806px; 
    }

    .from-converter-box {
        margin: 0 3px 0 0;
    }

    .to-converter-box {
        margin: 0 0 0 3px;
    }

    .summary-wrapper {
        flex-direction: row;
        width: 766px;
    }

    .half-width {
        width: 50%;
        position: relative;
    }

    .info-box {
        left: 50px;
    }

    .button-container {
        margin: auto 0 auto 60px;
    }
  }

